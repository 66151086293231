import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../../styles";
import { config } from "../../../../config";
import { CircularProgress, Link } from '@mui/material';
import classNames from 'classnames';

type Props = {};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '10px',
  },
  container: {
    display: 'flex',
    border: `2px solid ${config.colors.basic}`,
    borderRadius: '50%',
    width: '200px',
    height: '200px',
    // backgroundImage: '/avatar.jpg',
  },
  avatar: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    border: `2px solid ${config.colors.basic}`,
  },
  loading: {
    margin: 'auto auto !important',
  },
  hidden: {
    display: 'none !important',
  }
})

export const Logo: React.FC<Props> = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() =>  {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <CircularProgress
          className={classNames([
            classes.loading,
            !loading ? classes.hidden : void 0
          ])}
        />
        <Link
          className={loading ? classes.hidden : void 0}
          href={`${config.protocol}://${config.domain}`}
        >
          <img
            src={`${config.s3.route}/avatar.jpg`}
            className={classes.avatar}
            alt={`${config.content.name}. ${config.content.role}`}
          />
        </Link>
      </div>
    </div>
  );
}