import React from 'react';
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@mui/material";
import { config } from "../../../../config";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    lineHeight: '30px',
  }
})

type Props = {};

export const RoleTitle: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant={'h2'} className={classes.text}>
        {config.content.role}
      </Typography>

    </div>
  );

}