import React, { useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { eq, find, get, isNull, isUndefined, map, orderBy, replace } from 'lodash';
import { CV_PERIOD_LIST, CvPeriod } from '../../../resources/cv.model';
import { Uid } from '../../../resources/common.model';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Typography } from '@mui/material';
import { Maybe, Nullable } from '../../../types';
import { EMPTY_ARRAY, EMPTY_STRING, HASH } from '../../../utils/common';
import { CvContext } from '../../../context/CvContext';
import { ExpandMore, Attachment } from '@mui/icons-material';
import { CvContentSection } from './cv-content-section';
import { theme } from '../../../styles/theme';
import { localeDate } from '../../../utils/date';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 46, 48, .6)',
    paddingTop: '1px',
  },
  accordion: {
    width: '100%',
    marginBottom: '1px',
    boxShadow: 'none !important',
    // borderRadius: '20px !important'
  },
  accordionExpanded: {
    // margin: '0 auto !important',
  },
  accordionSummaryExpanded: {
    borderBottom: `1px solid ${theme.palette.primary.main} !important`,
  },
  dateSection: {
    height: '21px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
  dateValue: {
    marginTop: '-3px !important',
  },
  emptySection: {
    textAlign: 'center',
    marginLeft: '10px !important',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    paddingLeft: '20px'
  },
  summaryContainer: {
    paddingLeft: '4px',
    width: '100%',
    textAlign: 'center'
  },
  titleGrid: {
    flexBasis: '100% !important',
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  summary: {
    border: `1px solid rgba(0, 46, 48, .3) !important`
  },
})

type Props = {};

export const Experience: React.FC<Props> = () => {
  const classes = useStyles();

  const { list, isExpanded, expand } = useContext(CvContext);

  const accordionRef = useRef<Nullable<HTMLDivElement>[]>(EMPTY_ARRAY);

  function sortList(list: CvPeriod[]) {
    return orderBy(list, [({ period: { start } }: CvPeriod) => start], 'desc');
  }

  function getHashUid(): Maybe<Uid> {
    const hash = window.location.hash;
    return !isUndefined(hash)
      ? get(find(list, ({ code }: CvPeriod) => eq(code, replace(hash, HASH, EMPTY_STRING))), 'uid', void 0)
      : void 0;
  }

  useEffect(() => {
    const hashUid = getHashUid();
    if (!isUndefined(hashUid)) {
      const key = sortList(list).findIndex(({ uid }: CvPeriod) => eq(uid, hashUid));
      if (!isUndefined(key) && !isNull(key)) {
        const element = accordionRef.current[key];
        if (!isNull(element)) {
          const scrollTop = element.offsetTop;
          window.scrollTo({
            top: scrollTop - 100,
          });
        }
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      {map(CV_PERIOD_LIST, ({ uid, companyTitle, code, companyLink, activity, role, period }: CvPeriod, key: number) => {
        const hashUid = getHashUid();
        return (
          <Accordion
            disableGutters
            ref={(element: Nullable<HTMLDivElement>) => accordionRef.current[key] = element}
            defaultExpanded={!isUndefined(hashUid) && eq(uid, hashUid)}
            expanded={isExpanded[uid]}
            onChange={(event: React.SyntheticEvent, _: boolean) => {
              if (!eq(get(event, 'target.innerText', void 0), HASH)) {
                expand(uid);
              }
            }}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              id={uid}
              className={classes.summary}
            >
              <Grid
                container
                className={classes.summaryContainer}
              >
                <Grid item xs={12} sm={6} className={classes.titleGrid}>
                  <div className={classes.title}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant={'h4'}>
                          {companyTitle}
                          <Link title={companyTitle} href={companyLink}>
                            {/*<Attachment />*/}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={'body2'}>
                          {localeDate(period.start)} - {localeDate(period.end)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <CvContentSection
                role={role}
                period={period}
                activity={activity}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}