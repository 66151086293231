import { get, join, split } from 'lodash';

import { COMMA, SPACE } from './common';
import { Maybe } from '../types';

const DEFAULT_LOCALE = 'en-US';

export function localeDate(date: Maybe<Date>, options?: Maybe<LocaleDateOptions>, locale: string = navigator.language): string {
  if (date === void 0) {
    return 'present...';
  }
  const defaultLocalDate = date.toLocaleDateString(
    DEFAULT_LOCALE,
    {
      weekday: get(options, 'hasWeekday', false) ? 'long' : void 0,
      year: 'numeric',
      month: 'long',
      day: get(options, 'hasDay', false) ? 'numeric' : void 0,
    });
  const currentLocaleDate = date.toLocaleDateString(
    locale,
    {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  const enDateFormat = join([
    split(currentLocaleDate, `${COMMA}${SPACE}`)[1],
    split(currentLocaleDate, `${COMMA}${SPACE}`)[2]
  ], `${COMMA}${SPACE}`);
  const ruDateFormat = split(currentLocaleDate, `${COMMA}${SPACE}`)[1];

  // @note: compose the full list formatter through the list of format locales
  const dateFormat: string = {
    ['en-US']: enDateFormat,
    ['en-AU']: ruDateFormat,
    ['ru-RU']: ruDateFormat,
  }[locale] || defaultLocalDate;

  return dateFormat;
}

type LocaleDateOptions = {
  hasDay?: Maybe<boolean>,
  hasWeekday?: Maybe<boolean>,
}