import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CvPeriodActivity, CvPeriodDate } from '../../../../resources/cv.model';
import { Avatar, Grid, Stack, Typography } from '@mui/material';
import { localeDate } from '../../../../utils/date';
import { map } from 'lodash';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  activityGrid: {
    paddingTop: '20px',
  },
  activityItem: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row'
  },
  roleGrid: {
    width: '100%',
  },
  role: {
    textAlign: 'center',
    paddingTop: '10px',
  },
  periodGrid: {
    width: '100%',
  },
  period: {
    textAlign: 'center',
    width: '100%',
  }
})

type Props = {
  role: string;
  period: CvPeriodDate;
  activity: CvPeriodActivity[];
};

export const CvContentSection: React.FC<Props> = ({ role, period, activity }) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item sm={12} className={classes.roleGrid}>
          <Typography variant={'h4'} className={classes.role}>
            {role}
          </Typography>
        </Grid>
        {/*<Grid item sm={12} className={classes.periodGrid}>*/}
        {/*  <Typography variant={'body2'} className={classes.period}>*/}
        {/*    {localeDate(period.start)} - {localeDate(period.end)}*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
        <Grid item sm={12} className={classes.activityGrid}>
          <Grid container>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={8}>
              <Stack spacing={2}>
                {map(activity, (item: CvPeriodActivity, key: number) => {
                  return (
                    <div className={classes.activityItem}>
                      <Avatar sx={{ width: 12, height: 12, fontSize: 6, marginTop: 0.8, marginRight: 0.5, fontWeight: 'bold' }}>{item[0]}</Avatar>
                      <Typography variant={'body1'}>
                        {item}
                      </Typography>
                    </div>
                  );
                })}
              </Stack>
            </Grid>
            <Grid item xs={0} md={2} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}