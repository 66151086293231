import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Link, Paper, Typography } from '@mui/material';
import { map } from 'lodash';
import { Project, PROJECT_LIST } from '../../../resources/project.model';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
  },
  paper: {
    marginBottom: '20px',
  },
  gridContainer: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  logoGridItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  logoGrid: {
    paddingTop: '20px',
  },
  linkGrid: {
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none !important',
    fontSize: '14px !important'
  },
  titleGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  descriptionGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  descriptionItem: {
    fontSize: '13px !important',
    lineHeight: '20px !important',
  }
})

type Props = {};

export const Projects: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {map(PROJECT_LIST, ({ title, description, logo, link }: Project, key: number) => {
        return (
          <Paper elevation={5} className={classes.paper}>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={12} className={classes.titleGrid}>
                <Typography variant={'h5'}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} direction={'column'} className={classes.descriptionGrid}>
                {map(description, (desc: string, descKey: number) => {
                  return (
                    <div>
                      <Typography variant={'body1'} className={classes.descriptionItem}>
                        {desc}
                      </Typography>
                    </div>
                  );
                })}
              </Grid>
              <Grid item xs={12}>
                <Link href={link} target={'_blank'} className={classes.linkGrid}>
                  <Typography variant={'body1'} className={classes.link}>
                    {link}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} className={classes.logoGrid}>
                <Grid container>
                  <Grid item xs={3} />
                  <Grid item xs={6} className={classes.logoGridItem}>
                    <Link href={link} target={'_blank'}>
                      <img
                        src={logo.src}
                        alt={description[0]}
                        width={logo.width}
                        height={logo.height}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )
      })}
    </div>
  );
}