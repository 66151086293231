import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@mui/material';
import { get, isUndefined } from 'lodash';
import { Maybe } from '../../../types';
import classNames from 'classnames';
import classnames from 'classnames';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  children: {
    display: 'flex',
    justifyContent: 'center',
  },
  titleGrid: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '20px'
  },
  body: {
    paddingBottom: '20px',
  }
})

type Props = {
  title: string;
  icon?: Maybe<React.ReactElement>;
  children: React.ReactElement;
  className?: {
    root?: Maybe<string>;
    iconGrid?: Maybe<string>;
  };
};

export const Section: React.FC<Props> = ({ title, icon, children, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames([classes.root, get(className, 'root', void 0)])}>
      <Grid container>
        <Grid item xs={12} className={classes.titleGrid}>
          <Typography variant={'h3'} className={classes.title}>
            {title}
          </Typography>
        </Grid>
        {
          !isUndefined(icon)
            ? (
              <Grid
                item
                xs={12}
                className={classnames([
                  classes.iconGrid,
                  get(className, 'iconGrid', void 0)
                ])}
              >
                {icon}
              </Grid>
            )
            : void 0
        }
        <Grid item xs={12} className={classes.body}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}