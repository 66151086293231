import React, { Context, createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compact, concat, get, join, lowerCase, map, merge, noop } from 'lodash';

import { EMPTY_STRING, SPACE } from 'src/utils/common';
import { CvPeriod, CV_PERIOD_LIST } from 'src/resources/cv.model';
import { Uid } from 'src/resources/common.model';

export type Expand = { [uid: Uid]: boolean };

type CvContextValue = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  list: CvPeriod[];
  search: string;
  setSearch: (search: string) => void;
  isExpanded: Expand,
  expand: (code: Uid) => void;
  setExpanded: (expand: Expand) => void;
};

export const CvContext: Context<CvContextValue> =
  createContext<CvContextValue>({
    loading: false,
    setLoading: (loading: boolean) => void 0,
    list: [],
    search: EMPTY_STRING,
    setSearch: (search: string) => void 0,
    isExpanded: {},
    expand: (code: Uid) => void 0,
    setExpanded: (expand: Expand) => void 0,
  });

export const CvProvider: React.FC<any> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(EMPTY_STRING);
  const [list, setList] = useState<CvPeriod[]>(CV_PERIOD_LIST);
  const [isExpanded, setExpanded] = useState<Expand>({});

  function expand(code: Uid) {
    setExpanded(merge(isExpanded, {
      [code]: !get(isExpanded, 'code', false),
    }));
  }

  useEffect(() => {
    setExpanded({});
    const poemList = map(CV_PERIOD_LIST, (period: CvPeriod, key: number) => {
      const contentValue = join(
        concat(
          map(
            get(period, 'content', []),
            (stanza: string[], stanzaKey: number) => join(stanza, SPACE)
          ),
          get(period, 'title', void 0),
        ),
        SPACE
      );
      const searchPosition = contentValue
        .search(new RegExp(`${lowerCase(search)}`, 'i'));
      return searchPosition === -1 ? void 0 : period;
    });
    setList(compact(poemList));
  }, [search]);

  useEffect(() => {
    setList(CV_PERIOD_LIST);
  }, []);

  return (
    <CvContext.Provider value={{
      loading,
      setLoading,
      list,
      search,
      setSearch,
      expand,
      isExpanded,
      setExpanded,
    }}>
      {children}
    </CvContext.Provider>
  );
};

CvProvider.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.any,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
