import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { map } from 'lodash';
import { Project, PROJECT_LIST } from '../../../resources/project.model';
import { Grid, Link, Paper, Typography } from '@mui/material';
import { Publication, PUBLICATION_LIST } from '../../../resources/publication.model';
import { localeDate } from '../../../utils/date';
import { Education, EDUCATION_LIST } from '../../../resources/education.model';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
  },
  paper: {
    marginBottom: '20px',
  },
  gridContainer: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  logoGridItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  logoGrid: {
    paddingTop: '10px',
  },
  titleGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '10px',
  },
  dateGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  date: {
    fontSize: '12px !important',
    textDecoration: 'underline',
  },
  descriptionGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 20px 0 20px',
  },
  descriptionItem: {
    fontSize: '13px !important',
    lineHeight: '20px !important',
  }
})

type Props = {};

export const EducationSection: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={0} sm={2} />
        <Grid item xs={12}>
          {map(EDUCATION_LIST, ({ role, description, period: { start, end }, logo, link }: Education, key: number) => {
            return (
              <Paper elevation={5} className={classes.paper}>
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={12} className={classes.titleGrid}>
                    <Typography variant={'h5'}>
                      {role}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.dateGrid}>
                    <Typography variant={'body2'} className={classes.date}>
                      {localeDate(start)} - {localeDate(end)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} direction={'column'} className={classes.descriptionGrid}>
                    <Typography variant={'body1'} className={classes.descriptionItem}>
                      {description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.logoGrid}>
                    <Grid container>
                      <Grid item xs={3} />
                      <Grid item xs={6} className={classes.logoGridItem}>
                        <Link href={link} target={'_blank'}>
                          <img
                            src={logo}
                            alt={description[0]}
                            width={'200px'}
                            height={'208px'}
                          />
                        </Link>
                      </Grid>
                      <Grid item xs={3} />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
        </Grid>
        <Grid item xs={0} sm={2} />
      </Grid>
    </div>
  );
}
