import React from 'react';
import { Grid } from "@mui/material";

import { Logo } from './logo';
import { RoleTitle } from './role-title';
import { AuthorTitle } from './author-title';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logo: {
    paddingBottom: '20px'
  },
  authorTitle: {
    paddingBottom: '30px'
  },
  role: {
    paddingBottom: '20px'
  }
})

type Props = {};

export const Header: React.FC<Props> = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.logo}>
          <Logo />
        </Grid>
        <Grid item xs={12} className={classes.authorTitle}>
          <AuthorTitle />
        </Grid>
        <Grid item xs={12} className={classes.role}>
          <RoleTitle />
        </Grid>
      </Grid>
    </div>
  );
}