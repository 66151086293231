import { config } from '../config';

export type Education = {
  role: string;
  description: string;
  period: EducationPeriod;
  logo: string;
  link: string;
};

export type EducationPeriod = {
  start: Date;
  end: Date;
};

export const EDUCATION_LIST: Education[] = [
  {
    role: `MSc in Technical Sciences, Engineering Psychology, Ergonomics`,
    description: 'As a MSc in Technical Sciences, my educational background involves the application of psychological science principles in the context of designing everyday products, systems, and devices. Specifically, I have focused on the development of a "smart house" that addresses and resolves intricate technical challenges associated with integrating complex household electronic systems into the domain of software engineering. The primary objectives of this endeavor have been to ensure the safety and operational efficiency of these integrated electronic systems, ultimately leading to a high level of control and performance within the smart house environment. This educational journey has equipped me with the knowledge and expertise necessary to tackle the technical intricacies of merging psychology and engineering to create smart, efficient, and safe living spaces.',
    period: {
      start: new Date(2013, 8, 1),
      end: new Date(2015, 6, 1),
    },
    logo: `${config.s3.route}/bsuir-logo.png`,
    link: 'https://www.bsuir.by/en/',
  },
  {
    role: `Bachelor's degree, Radioengineering Systems`,
    description: 'I specialised in a branch of electrical engineering focused on electromagnetic radiation, including areas such as radio waves, broadcasting, navigation, and television. The goal was to develop proficiency in managing radioelectronic software using existing tools. Consequently, I graduated with a comprehensive understanding of radio engineering schematics and the capability to control them through software within a processor.',
    period: {
      start: new Date(2008, 8, 1),
      end: new Date(2013, 6, 1),
    },
    logo: `${config.s3.route}/bsuir-logo.png`,
    link: 'https://www.bsuir.by/en/',
  },
]