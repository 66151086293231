import React from 'react';
import { Grid } from "@mui/material";
import { Header } from './header';

type Props = {
  children: React.ReactElement
};

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <Grid container>
      <Grid item sm={0} md={2}/>
      <Grid item sm={12} md={8}>
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={0} md={2}/>
    </Grid>
  );
}