import React, { forwardRef, Ref, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { map } from 'lodash';
import { Contact, CONTACT_LIST } from '../../../resources/contact.model';
import { theme } from '../../../styles/theme';
import { Maybe } from '../../../types';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '-28px',
  },
  contactGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  contactItem: {
    width: '250px',
    margin: '10px 20px !important',
    fontSize: '17px !important',
    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        marginTop: '0 !important',
      }
    },
  },
  typeGrid: {
    textAlign: 'center'
  },
  typeVariant: {
    fontSize: '13px !important'
  },
  locationVariant: {
    fontSize: '14px !important'
  }
})

type Props = {
  ref: Maybe<Ref<HTMLDivElement>>;
};

export const ContactSection: React.FC<Props> = forwardRef<HTMLDivElement>(({}, ref) => {
  const classes = useStyle();

  const [isClicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    const TOOLTIP_TIMEOUT_TIME = 3000;
    setTimeout(() => {
      setClicked(false);
    }, TOOLTIP_TIMEOUT_TIME);
  }, [isClicked]);

  return (
    <div className={classes.root} ref={ref}>
      <Grid container flexDirection={'column'}>
        <Grid item xs={12}>
          <Grid container flexDirection={'row'}>
            <Grid
              item
              xs={12}
              className={classes.contactGrid}
              alignItems={'center'}
            >
              {map(CONTACT_LIST, ({ uid, action, text, icon, tooltip }: Contact, key: number) => {
                return (
                  <Tooltip title={tooltip.active ? isClicked ? tooltip.active : tooltip.inactive : tooltip.inactive}>
                    <label htmlFor="contact">
                      <IconButton
                        onClick={() => {
                          setClicked(true);
                          action();
                        }}
                        color="primary"
                        component="span"
                      >
                        {icon}
                      </IconButton>
                    </label>
                  </Tooltip>
                );
              })}
            </Grid>
            <Grid item xs={12} className={classes.typeGrid}>
              <Typography variant={'body1'} className={classes.locationVariant}>
                Poland
              </Typography>
              <Typography variant={'body1'} className={classes.typeVariant}>
                Office / Hybrid / Remote
              </Typography>
              <Typography variant={'body2'}>
                Relocation or business trips are available
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr/>
        </Grid>
      </Grid>
    </div>
  );
});