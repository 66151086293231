import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    padding: '0 10px 0 10px',
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    paddingTop: '20px',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center'
  }
})

type Props = {};

export const Overview: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant={'body1'} className={classes.text}>
        I am a Tech Innovator and Thought Leader, with over 12 years of experience in engineering, solution architecture, team leadership, and management. My focus is on bridging technology and human insight, creating advanced solutions that integrate AI-driven automation and cutting-edge business infrastructure. Alongside my technical expertise, I bring strong management skills, overseeing projects and leading teams to deliver successful, impactful outcomes, while fostering a collaborative and innovative work environment.
      </Typography>
      <Typography variant={'body1'} className={classes.text}>
        Recently, I have pioneered a new paradigm in AI-based infrastructure automation, where business requirements, not technical constraints, dictate how resources are deployed and scaled. This innovation drastically simplifies how businesses interact with technology.
      </Typography>
      <Typography variant={'body1'} className={classes.text}>
        I am equally passionate about leadership, fostering collaborative, forward-thinking environments that inspire teams to achieve impactful outcomes. In addition to my technical expertise, I bring significant management experience, leading projects, guiding teams, and ensuring successful execution of business objectives. By blending technical proficiency with a deeper understanding of philosophical concepts, I aim to push the boundaries of both technology and human thought, driving meaningful innovation while effectively managing and inspiring teams.
      </Typography>
    </div>
  );
}