import React from 'react';
import { Link, Typography } from '@mui/material';

import { config } from '../../../../config';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none !important',
  },
  title: {
    // lineHeight: '50px !important', // @note: galaxy mobile style invalid
  }
})

type Props = {};

export const AuthorTitle: React.FC<Props> = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Typography variant={'h1'} className={classes.title}>
        <Link
          href={`${config.protocol}://${config.domain}`}
          className={classes.link}
        >
          {config.content.name}
        </Link>
      </Typography>
    </div>
  );
}