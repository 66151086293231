import { config } from '../config';

export type Publication = {
  title: string;
  description: string;
  date: Date;
  logo: string;
  link: string;
};

export const PUBLICATION_LIST: Publication[] = [
  {
    title: 'Theory of the Essence: A New Paradigm for Understanding Reality',
    description: 'The Theory of the Essence introduces a revolutionary framework that redefines our understanding of the fundamental nature of reality, time, and existence. By proposing the concept of Essence as the core structure that unites all aspects of reality — past, present, and future — this theory addresses not only the mechanics of the universe but also the deeper question of why reality exists in its current form. Essence is the ultimate foundation of reality, and its intrinsic striving toward truth guides the unfolding of time and existence toward greater understanding and realization. This theory has profound implications for both scientific and philosophical inquiry, providing new avenues for research into the nature of time, causality, and the evolution of truth.',
    date: new Date(2024, 8, 27),
    logo: `/academia-logo.png`,
    link: 'https://www.academia.edu/124232327/Theory_of_the_Essence_A_New_Paradigm_for_Understanding_Reality',
  },
  {
    title: 'Versioning Automation Management Tool',
    description: 'You have always been interested what does the version of the product mean and how to manage these versions. Let’s consider these questions.',
    date: new Date(2018, 11, 29),
    logo: `${config.s3.route}/reddit-logo.png`,
    link: 'https://www.reddit.com/r/opensource/comments/aaewcn/versioning_automation_management_tool/',
  },
  {
    title: 'Инструмент автоматизации управления версиями',
    description: 'Всегда было интересно, что такое версии продукта и как ими управлять? Как автоматизировать управление версиями разработки? Постараемся ответить на эти вопросы.',
    date: new Date(2018, 11, 21),
    logo: `${config.s3.route}/habr-logo.png`,
    link: 'https://habr.com/en/articles/433994/', // @todo: publication link
  },
];