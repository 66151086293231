import React from 'react';
import './App.css';
import { Layout } from "./components/application";
import { Content } from './components/content';
import { CvProvider } from './context/CvContext';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './styles/theme';

type Props = {};

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <CvProvider>
          <Layout>
            <Content />
          </Layout>
        </CvProvider>
    </ThemeProvider>
  );
}

export default App;
